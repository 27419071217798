import React from 'react';
import {  graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import ExternalLink from "../../static/svg/icon-external-link.inline.svg";



import SEO from "../../components/seo";
import LayoutSub from "../../components/layoutSub";
import NishiogiMap from "../../components/nishiogiMap";




function Access({data, pageContext, location}) {

  const { breadcrumb: { crumbs } } = pageContext;
  
  const {pathname} = location;
  const pagetitle = "Nishiogikubo: Nishiogi Osteopathy Center";
  const description = "Nishiogikubo: Are you looking for relief from pain or discomfort?  We're here to help you at Penguindou Nishiogikubo branch.";

    return (
      <div>

        <LayoutSub crumbs={crumbs} pathname={pathname} lang='en' >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
            lang="en"
          />

          <div>
            <h1 className="heading-b">Access</h1>

            <p className="mt-6">
              <a href="https://www.google.com/maps/place/%E8%BA%AB%E4%BD%93%E3%81%AE%E8%AA%BF%E6%95%B4+%E3%81%BA%E3%82%93%E3%81%8E%E3%82%93%E5%A0%82%E3%82%AA%E3%82%B9%E3%83%86%E3%82%AA%E3%83%91%E3%82%B7%E3%83%BC/@35.7008897,139.6001098,16z/data=!4m5!3m4!1s0x6018efb916a1ec21:0x130ac75b617c9130!8m2!3d35.7008897!4d139.6001098?hl=en" target="_blank" rel="noreferrer" >GoogleMap
                <ExternalLink className="inline  w-4 h-4 align-text-bottom pb-0.5" />
              </a></p>
            <p className="mt-2">
            5 minutes from Nishiogikubo sta.&nbsp;&#40;JB03&#41; on JR Chuo/Sobu line
            </p>
            <div className="mb-6 responsive-map">
          <StaticImage
              src="../../images/NishiogiMapEn.jpg"
              alt="西荻窪駅南口からぺんぎん堂西荻窪院までの地図"
              placeholder="tracedSVG"
              layout="constrained"
              width={534}
           />
            </div>

            <NishiogiMap lang='en' />

          </div>


        </LayoutSub>
      </div>

      
    )
}

export default Access;

export const query = graphql`
query {
  site {
  siteMetadata {
  siteUrl
  address
  tel
  postalcode
}
}
}
`